<template>
  <v-app id="app" class="d-flex">
    <v-container
      fluid
      :class="{ 'd-flex': $vuetify.breakpoint.mdAndUp }"
      class="pa-0 ma-0"
      style="width: 100%"
    >
      <MobileNavbar
        v-if="!$vuetify.breakpoint.mdAndUp && routeCheck"
        :key="sidebarMobileKey"
      />
      <Sidebar
        v-if="$vuetify.breakpoint.mdAndUp && routeCheck"
        :key="sidebarMobileKey"
      />

      <v-sheet :class="[!liveShareRoute ? 'content' : 'liveShare']">
        <router-view />
      </v-sheet>
      <Loader v-if="isLoading" />
    </v-container>
  </v-app>
</template>

<script>
import Sidebar from './components/Navbar/Sidebar.vue'
import MobileNavbar from './components/Navbar/MobileNavbar.vue'
import Loader from './components/Loader'
import loader from './components/Livetracking/use/loader'
import ApiBackend from '@/backend/apiBackend'
import HosBackend from '@/backend/hosBackend'
import IntegrationBackend from '@/backend/integrationBackend'
import SupportPanelBackend from '@/backend/supportPanelBackend'
import getCompanySettingsFromToken from '@/components/use/companySettingsFromToken'
import featuresSettings from '@/components/Administration/CompanySettings/Features/use/features'
import { TokenService } from '@/services/storage.service'
import AlertsService from '@/services/alerts/AlertsService.js'
import useAssets from '@/components/Livetracking/use/useAssets'

export default {
  name: 'App',
  components: {
    MobileNavbar,
    Sidebar,
    Loader
  },
  setup() {
    const { showLoader, toggleLoader } = loader()
    const {
      getLanguage,
      getChildCompanySettings,
      getToken,
      getSupportToken,
      getCompanySettings,
      getUserRoles,
      getUserId,
      getCompanyFeatures,
      getChildCompanyFeatures,
      getUsername,
      getCurrentChildCompany
    } = getCompanySettingsFromToken()
    const { features } = featuresSettings()
    const { updateUnReviewedAlerts } = useAssets()
    return {
      showLoader,
      getLanguage,
      getChildCompanySettings,
      getToken,
      getSupportToken,
      features,
      getCompanySettings,
      getUserRoles,
      getUserId,
      getCompanyFeatures,
      getChildCompanyFeatures,
      getUsername,
      getCurrentChildCompany,
      updateUnReviewedAlerts,
      toggleLoader
    }
  },
  data() {
    return {
      sidebarMobileKey: 0,
      pendingCalls: 0
    }
  },
  computed: {
    routeCheck() {
      return (
        this.$route.path !== '/' &&
        this.$route.path !== '/resetPassword' &&
        !this.liveShareRoute
      )
    },
    liveShareRoute() {
      return this.$route.name === 'LiveShare'
    },
    isLoading() {
      return this.pendingCalls > 0 || this.showLoader
    },
    companySettings() {
      return this.getChildCompanySettings
        ? this.getChildCompanySettings
        : this.getCompanySettings
    }
  },
  watch: {
    features() {
      this.sidebarMobileKey++
    }
  },
  async created() {
    SupportPanelBackend.init()
    if (localStorage.getItem('language')) {
      this.$i18n.locale = localStorage.getItem('language')
    } else {
      this.$i18n.locale = 'en'
    }
    if (!this.getChildCompanySettings) {
      TokenService.setCurrentChildCompany(
        JSON.parse(localStorage.getItem('current_child_company'))
      )
    }
    if (this.getChildCompanySettings) {
      ApiBackend.setChildCompanyAccessToken()
      HosBackend.setChildCompanyAccessToken()
      IntegrationBackend.setChildCompanyAccessToken()
    }
    if (!this.getToken) {
      TokenService.setToken(localStorage.getItem('access_token'))
    }
    if (!this.getChildCompanySettings) {
      ApiBackend.setHeader()
      HosBackend.setHeader()
      IntegrationBackend.setHeader()
    }
    if (this.getSupportToken) {
      ApiBackend.setSupportAccessToken()
      HosBackend.setSupportAccessToken()
      IntegrationBackend.setSupportAccessToken()
    }
    if (!this.getCurrentChildCompany) {
      TokenService.setCurrentChildCompany(
        JSON.parse(localStorage.getItem('current_child_company'))
      )
    }
    if (!this.getCompanySettings) {
      TokenService.setCompanySettings(
        JSON.parse(localStorage.getItem('company_settings'))
      )
    }
    if (!this.getUserRoles) {
      TokenService.setUserRole(JSON.parse(localStorage.getItem('user_role')))
    }
    if (!this.getUserId) {
      TokenService.setUserId(JSON.parse(localStorage.getItem('userId')))
    }
    if (!this.getCompanyFeatures && localStorage.getItem('company_features')) {
      TokenService.setCompanyFeatures(
        JSON.parse(localStorage.getItem('company_features'))
      )
    }
    if (!this.getChildCompanyFeatures) {
      TokenService.setChildCompanyFeatures(
        JSON.parse(localStorage.getItem('child_company_features'))
      )
    }
    if (!this.getUsername) {
      TokenService.setUsername(localStorage.getItem('username'))
    }
    this.setupApiInterceptors()
    if (this.$route.name && this.$route.name !== 'Login') {
      await this.getAlerts()
    }
  },
  mounted() {
    if (!window.google) {
      const script = document.createElement('script')
      script.async = true
      script.defer = true
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_APY_KEY}&loading=async&libraries=places`
      script.onload = this.initAutocomplete
      script.onerror = this.scriptLoadError
      document.head.appendChild(script)
    }
  },
  methods: {
    setupApiInterceptors() {
      this.backendInterceptor(ApiBackend)
      this.backendInterceptor(HosBackend)
      this.backendInterceptor(IntegrationBackend)
      this.backendInterceptor(SupportPanelBackend)
    },
    backendInterceptor(backend) {
      const excludedPatterns = [
        /^\/Device\/GetConfiguration\/\d+$/,
        /^\/Device\/LastReceivedTime\/\d+$/,
        /^\/Device\/GetDeviceLogPaged\?.*$/,
        /^\/Device\/Commands\/\d+$/
      ]

      backend.client.interceptors.request.use(config => {
        const url = new URL(config.url, window.location.origin)
        const endpoint = url.pathname
        const isExcluded = excludedPatterns.some(pattern =>
          pattern.test(endpoint)
        )

        if (
          this.$route.name !== 'Login' &&
          !isExcluded &&
          !config.url.includes('Device/GetDeviceLogPaged') &&
          !config.url.includes('diagnostic?assetId') &&
          !config.url.includes('AssetTirePressure/latest') &&
          !(
            this.$route.name === 'Dispatch' &&
            config.url.includes('asset/Abbreviated')
          ) &&
          !(this.$route.name === 'Dispatch' && config.url.includes('Client')) &&
          !(this.$route.name === 'Dispatch' && config.url.includes('poi'))
        ) {
          this.pendingCalls++
          this.toggleLoader(true)
        }
        return config
      })

      backend.client.interceptors.response.use(
        response => {
          const url = new URL(response.config.url, window.location.origin)
          const endpoint = url.pathname
          const isExcluded = excludedPatterns.some(pattern =>
            pattern.test(endpoint)
          )

          if (
            this.$route.name !== 'Login' &&
            !isExcluded &&
            !response.config.url.includes('Device/GetDeviceLogPaged') &&
            !response.config.url.includes('diagnostic?assetId') &&
            !response.config.url.includes('AssetTirePressure/latest') &&
            !(
              this.$route.name === 'Dispatch' &&
              response.config.url.includes('asset/Abbreviated')
            ) &&
            !(
              this.$route.name === 'Dispatch' &&
              response.config.url.includes('Client')
            ) &&
            !(
              this.$route.name === 'Dispatch' &&
              response.config.url.includes('poi')
            )
          ) {
            this.pendingCalls--
            if (this.pendingCalls === 0) {
              this.toggleLoader(false)
            }
          }
          return response
        },
        error => {
          const url = new URL(error.config.url, window.location.origin)
          const endpoint = url.pathname
          const isExcluded = excludedPatterns.some(pattern =>
            pattern.test(endpoint)
          )

          if (
            this.$route.name !== 'Login' &&
            !isExcluded &&
            !error.config.url.includes('Device/GetDeviceLogPaged') &&
            !error.config.url.includes('diagnostic?assetId') &&
            !error.config.url.includes('AssetTirePressure/latest') &&
            !(
              this.$route.name === 'Dispatch' &&
              error.config.url.includes('asset/Abbreviated')
            ) &&
            !(
              this.$route.name === 'Dispatch' &&
              error.config.url.includes('Client')
            ) &&
            !(
              this.$route.name === 'Dispatch' &&
              error.config.url.includes('poi')
            )
          ) {
            this.pendingCalls--
            if (this.pendingCalls === 0) {
              this.toggleLoader(false)
            }
          }
          return Promise.reject(error)
        }
      )
    },
    async getAlerts() {
      try {
        const { filteredCount } = await AlertsService.getAllAlerts(
          `IsReviewed=false&CompanyId=${
            this.getChildCompanySettings
              ? this.getChildCompanySettings?.companyId
              : this.getCompanySettings?.companyId
          }`
        )
        this.updateUnReviewedAlerts(filteredCount, 1)
      } catch (error) {
        console.error('Error fetching alerts:', error)
      }
    }
  }
}
</script>
<style lang="postcss" scoped>
.sidebar-content {
  display: flex;
  height: 100%;
}
.content {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.liveShare {
  height: 100vh;
  width: 100% !important;
  overflow-y: auto;
}

@media (max-width: 960px) {
  .content {
    height: calc(100vh - 64px) !important;
  }
}
</style>
